import React from "react";
import ctabg1 from "../assets/images/Careers.jpeg";
import ctabg2 from "../assets/images/teachers.jpg"
const Career = () => {
  return (
    <section className="cta-three">
        <div className="container">
          <div className="careers-image row">
            <div className="careers">
              {/* <img src={ctabg2}  alt="" /> */}
            </div>
        </div>

        <div className="col-lg-12">
              <div className="cta-three__content">
                <div className="block-title text-center">
                  <h2 className="block-title__title">
                  VACANCIES FOR TEACHING/NON-TEACHING STAFF
                  </h2>
                </div>
                <p className="cta-three__text">
                SHANTI ASIATIC SCHOOL is a CBSE school committed to provide high-quality education to the students with indigenous background. It is well-equipped with the most up-to-date technology. Teachers with a vibrant personality, extended subject knowledge, and fluency in English are required as listed below:
                </p>
                <div className="careers">
              <img src={ctabg1}  alt="" />
            </div>
              </div>
            </div>
        </div>
      </section>
  );
};

export default Career;
