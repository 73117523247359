import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import Careers from '../components/Career';
import Topbar from "../components/Topbar";

const CareersPage = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | Careers">
            <Topbar />
            <NavOne />
            <PageHeader title="Careers" />
            <Careers />
            <Footer />
        </Layout>
    );
};

export default CareersPage;
